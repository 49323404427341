import { useState, useEffect, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const Pages = forwardRef(({ children, title = '', refreshPageComponent, ...other }, ref) => {
  const [refreshComp, setRefreshComp] = useState(null);
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state?.loginReducer?.loginInfo);

  useEffect(() => {
    let fetchUserDetailssessionStorage = userDetails;
    if (fetchUserDetailssessionStorage === 'null' || fetchUserDetailssessionStorage === null) {
      fetchUserDetailssessionStorage = '{"isLogedIn":false}';
    }
  }, [refreshComp, refreshPageComponent]);
  return (
    <Box ref={ref} {...other}>
      <Helmet>{/* <title>{title}</title> */}</Helmet>
      {children}
    </Box>
  );
});

Pages.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Pages;
