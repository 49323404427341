import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import add from '@iconify/icons-ic/add';
import exportIcon from '@iconify/icons-ant-design/export-outline';
import { MdSystemUpdateAlt } from 'react-icons/md';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment
} from '@mui/material';
import { navigate } from '@storybook/addon-links';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 45,
  display: 'flex',
  justifyContent: 'space-between'
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  paddingTop: 2,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

export default function UserListToolbar({ numSelected, filterName, onFilterName, parent }) {
  const navigate = useNavigate();
  const AddUserPermissions = sessionStorage.getItem('adminAccessDetails');
  const AddUserJourneyPermission = JSON.parse(AddUserPermissions);
  const isUserAccess = AddUserJourneyPermission.filter((item) => item?.adminPageCode === 'USERS');
  return (
    <RootStyle
      sx={{
        minHeight: 45,
        borderBottom: '1px solid #eaedf1',
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter'
        })
      }}
    >
      <Box>
        <Tooltip
          disabled={isUserAccess[0]?.addAccess === false ? true : false}
          title="Add New"
          placement="top"
          arrow
        >
          <IconButton className="squareIconButton" onClick={(e) => navigate(`/${parent}/add`)}>
            <Icon icon={add} width={18} />
          </IconButton>
        </Tooltip>
        &nbsp;&nbsp;&nbsp;
        <Tooltip title="Export data" placement="top" arrow>
          <IconButton className="squareIconButton">
            <MdSystemUpdateAlt size={18} />
          </IconButton>
        </Tooltip>
      </Box>
      <Box>
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder="Search Users"
          classes={{
            root: 'searchHolder',
            input: 'searchInput'
          }}
          startAdornment={
            <InputAdornment position="start">
              <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
      </Box>
    </RootStyle>
  );
}
